<template>
  <div class="product-order">
    <div class="text-center">
      <loading :active="isLoading" :can-cancel="false" :is-full-page="false" color="#FFF">
      </loading>
    </div>
    <div v-show="!isLoading" class="product-order-inner">
      <div class="product-order-body inner-wrapper container--full-width container-xl pl-0 pr-0">
        <div class="main-wrapper">
          <div class="left-wrapper">
            <div class="d-block text-center">
              <a href="https://10in30challenge.com/" target="_blank" class="d-block">
                <img class="logo" src="@/assets/images/product/30-days-challenge.webp" height="100" alt="logo" />
              </a>
            </div>
            <p class="title text-center mt-5">You’re about to get personalized help to find, close, and service 10 great
              new clients.
            </p>
            <p class="desc">Learn From 7-Figure Agency Owners Who Have Done It Many Times Before</p>
            <ul>
              <li><i class="fa fa-check green"></i> Easy ways to generate a massive amount of leads</li>
              <li><i class="fa fa-check green"></i> The best process to close your leads at very high rates</li>
              <li><i class="fa fa-check green"></i> How to set up your business for future growth</li>
            </ul>
            <div class="d-flex img-wrapper">
              <img class="logo" src="@/assets/images/product/30-days-challenge-img.webp" width="230" alt="img" />
              <p><i>The Life You Seek, With The Friends And Family You're After, Are Driven By The Business You Create</i>
              </p>
            </div>
            <p class="p1 green mt-5">If you're worried about having to do something to reach your goals, leave this page
              now.
            </p>
            <p class="p2 mt-3">If you're excited about getting advice and help to reach your goals faster, join now:</p>
          </div>
          <div class="right-wrapper">
            <p class="title text-center mt-3">Final Step: Enter Your Information to Join the Challenge</p>
            <div class="form-wrapper">
              <p class="red form-title">Cohort Starts:</p>
              <p class="form-title">Monday, May 1, 2023</p>
              <p class="red form-title">Limited Time Cost: ${{ formatPrice(totalPrice) }}</p>
              <p class="red mt-3 desc mb-5">This is the first edition of the 30 day challenge, so pricing is low<br />just
                this once to make it a no brainer. It will only go UP from here.</p>
              <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="productForm">
                <form @submit.prevent="handleSubmit(handleRegister)">
                  <div class="row">
                    <div class="col-md-12">
                      <text-input validate="required" :immediate="false" name="CompanyName" v-model="user.company_name"
                        :placeholder="'auth.order.company_name' | translate" />
                    </div>
                    <div class="col-md-12">
                      <text-input validate="required" :immediate="false" name="FullName" v-model="user.full_name"
                        :placeholder="'auth.order.full_name' | translate" />
                    </div>
                    <div class="col-md-12">
                      <text-input validate="required|email" :immediate="false" name="Email" v-model="user.email"
                        :placeholder="'auth.order.email' | translate" />
                    </div>
                    <div class="col-md-12">
                      <phone-number-input v-model="user.phone_number" name="PhoneNumber"
                        :placeholder="'auth.order.phone_number' | translate" :onlyCountries="[]"
                        :disabledFetchingCountry="false" />
                    </div>
                    <div class="col-md-12 mt-3">
                      <AddCreditCard :addPaymentMethod="false" :eventBus="eventbus" :showCoupon="false"
                        :showCardAlert="false" v-on:done="onCardVerified" v-on:failed="onCardFailed"></AddCreditCard>
                    </div>
                    <div class="col-md-12">
                      <Button type="submit" class="w-100 btn btn-success register-btn"
                        :disabled="processing || !totalPrice" :loading="processing">
                        <span>JOIN THE 30 DAY CHALLENGE NOW</span>
                        <span class="desc">Give Me Daily Training and Coaching to Reach My Goals</span>
                      </Button>
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
            <!-- <p class="text-center h3 font-weight-bold">(AND ONLY PAY <span class="line-through">$3011</span> $99 TO GET IT)</p> -->
          </div>
        </div>
      </div>
      <div class="product-order-footer">
        <div class="product-order-footer-inner inner-wrapper">
          <div class="main-wrapper">
            <div class="left-wrapper">
              <div class="d-block">
                <a href="https://10in30challenge.com/" target="_blank" class="d-inline-block">
                  <img class="logo" src="@/assets/images/product/30-days-challenge-footer.webp" height="60" alt="logo" />
                </a>
              </div>
              <span class="span-reserved">© 2023 10CLIENTS30DAYS. ALL RIGHTS RESERVED.</span>
            </div>
            <div class="right-wrapper">
              <div class="row mt-3">
                <div class="col-md-12">
                  <a href="https://10in30challenge.com/privacy" target="_blank">PRIVACY POLICY</a>
                </div>
              </div>
              <span class="span-policy-desc">The Challenge and Company is NOT an investment advisory service, NOT an
                investment advisor, <br />
                and does NOT serve as a financial advisor or provide personalized financial advice in any way</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import AddCreditCard from './AddCreditCard'
import Loading from 'vue-loading-overlay'
import postscribe from 'postscribe'

export default {
  components: {
    AddCreditCard, Loading
  },

  data() {
    return {
      isLoading: false,
      processing: false,
      eventbus: new Vue(),
      orderPage: null,
      prices: [],
      user: {
        company_name: '',
        full_name: '',
        email: '',
        phone_number: '',
        payment_method: undefined,
        slug: undefined,
      },
    }
  },

  beforeDestroy() {
    if ($('#custom-scripts')) {
      $('#custom-scripts').remove()
    }
  },

  mounted() {
    if (!$('#custom-scripts').length) {
      $('body').append(`<div id="custom-scripts"></div>`)
    }
    let script = `<script>!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '602443691819527');
fbq('track', 'PageView');<\/script>`

    if (process.env.NODE_ENV === 'production')
      postscribe('#custom-scripts', script, {
        done: () => {
        },
      })

    this.user.slug = this.$route.params.slug
    if (this.user.slug)
      this.getOrderPage(this.$route.params.slug);
    else
      this.$router.push({ name: 'register' });
  },

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    getOrderPage(slug) {
      this.isLoading = true;
      this.$store
        .dispatch('auth/getOrderPage', slug)
        .then((res) => {
          if (res) {
            this.orderPage = res
            this.prices = res.prices
            this.isLoading = false
          }
        })
        .catch(() => {
          this.isLoading = false
          this.$router.push({ name: 'register' });
        })
    },

    onCardVerified(arg) {
      const { id, coupon } = arg
      this.user.payment_method = id
      this.processing = false
      if (this.user.payment_method) {
        this.register();
      }
    },

    onCardFailed() {
      this.processing = false
    },

    handleRegister() {
      this.processing = true
      this.eventbus.$emit('onValidateCard')
    },

    register() {
      this.processing = true

      this.$store.dispatch('auth/orderProduct', this.user)
        .then((res) => {
          this.processing = false
          this.onSuccess()
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.productForm.setErrors(err.response.data.errors)
          }
          this.processing = false
        })
    },

    onSuccess() {
      if (this.orderPage && this.orderPage.redirect_url) {
        window.location.href = this.orderPage.redirect_url;
        return;
      }

      this.$router.push({ name: "product-thankyou" })
    }
  },
  computed: {
    totalPrice() {
      let res = this.prices.reduce((sum, item) => {
        return sum + item.unit_amount
      }, 0)
      return res / 100
    }
  },
}
</script>
  
<style lang="scss">
.product-order {
  padding-top: 30px;

  .inner-wrapper {
    margin: 0 auto;
    max-width: 1024px;
    padding: 10px 0px;
  }

  .product-order-footer {
    width: 100%;
    background-color: #fff;

    .product-order-footer-inner {
      padding: 10px 10px;
      font-weight: 600;
    }

    .main-wrapper {
      color: #000 !important;
    }

    .span-reserved {
      font-size: 13px;
      padding-top: 10px;
      display: block;
    }

    .span-policy-desc {
      display: block;
      font-size: 11px;
      padding-top: 10px;
    }
  }

  .red {
    color: #AD2023
  }

  .green {
    color: #4caf50;
  }

  .line-through {
    text-decoration: line-through;
  }

  .input,
  .multiselect__tags {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05);
    font-size: 16px;
    height: 42px;
  }

  .main-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: #F6F5FF;

    .left-wrapper {
      flex: 0 0 40%;

      .title {
        font-weight: 800;
        font-size: 34px;
        line-height: 42px;
        padding-bottom: 20px;
        border-bottom: 1px solid #4caf50;
      }

      .desc {
        font-size: 27px;
        line-height: 35px;
      }

      ul {
        list-style: none;
        margin: 30px 0;
        padding: 0;
      }

      .img-wrapper {
        p {
          padding-left: 20px;
          font-size: 19px;
          line-height: 23px;
        }
      }

      .p1 {
        font-weight: 800;
        font-size: 19px;
        line-height: 20px;
        font-style: italic;
      }

      .p2 {
        font-size: 18px;
        line-height: 23px;
      }
    }

    .right-wrapper {
      flex: 1 1 auto;
      padding-left: 50px;

      .title {
        font-weight: 800;
        font-style: italic;
        font-size: 21px;
      }

      .form-wrapper {
        padding: 40px 30px;
        margin: 30px 0;
        background-color: #F2F2F2;
        border: 1px solid #562EFF;
        color: #000;

        .form-title {
          text-align: center;
          font-weight: 800;
          font-size: 30px;
          line-height: 35px;
          padding: 0;
          margin: 0;
        }

        .desc {
          text-align: center;
          font-size: 15px;
          line-height: 19px;
          font-weight: 600;
        }

        .register-btn {
          padding: 15px 10px;
          margin-top: 20px;

          span {
            display: block;
            font-size: 24px;
            font-weight: bold;

            &.desc {
              padding-top: 5px;
              font-size: 14px;
              font-weight: normal;
            }
          }
        }
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;

      .left-wrapper {
        width: 100%;
        padding: 0 10px;
        text-align: center;

        .img-wrapper {
          flex-direction: column;

          img {
            margin-bottom: 10px;
          }
        }

        ul {
          text-align: left;
        }
      }

      .right-wrapper {
        width: 100%;
        padding: 0 10px;
        text-align: center;

        .register-btn {
          white-space: normal;
        }
      }
    }
  }
}</style>
  